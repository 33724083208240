@include breakpoint(tablet) {
	.fw-hero-block {
		min-height: 65rem;

		&:not(.full-width) {
			max-width: $content-grid-size;
		}

		&-img {
			min-height: 65rem;
		}
	
		&-content {
			max-width: 78.4rem;
			
			&.position-left {
				left: 0;
				margin-left: 29.6rem;
				transform: translateY(-50%);
			}
	
			&.position-right {
				left: auto;
				right: 0;
				margin-right: 29.6rem;
				transform: translateY(-50%);
			}

			.fw-hero-block-title {
				margin-bottom: 3rem;
			}
		}
	}
}