@include breakpoint(tablet) {
	.btn-block-wrapper {
		margin: 0;
	}

	.btn {
		font-size: 1.8rem;
		padding: 1.6rem 2.2rem;
	}

	.btn-link {
		padding: 0.5rem 0;
	}
}