@include breakpoint(tablet) {
    .accordion-block {
        .accordion-body {
            padding: 0 11.8rem 2.2rem 3.2rem;
        }
    }

    .theme-inservco {
        .accordion-block {
            .accordion-body {
                padding: 0;
            }
        }
    }
}
