@include breakpoint(tablet) {
	.icon-block {
		padding: 3rem;

		&-wrapper .grid {
			grid-gap: 3.2rem;
		}

		&-title {
			margin-bottom: 2.7rem;
		}

		&-noImg {
			border-bottom: none;
			border-right: 1px solid rgba($border-color, 0.3);
			padding: 3rem 3rem 3rem 0;
	
			&:first-child {
				padding: 3rem;
			}
	
			&:last-child {
				border-right: none;
			}
		}
	}
}