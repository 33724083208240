// Penn National Colors ============================================================
$white: #ffffff;
$burgundy: #792039;
$cyan: #007f8f;
$dark-blue: #145b7a;
$dark-grey: #222222;
$gold: #c09853;
$green: #6d7a2d;
$light-blue: #53a0d5;
$light-grey: #f1efec;
$middle-blue: #2a6496;
$middle-grey: #3F3F3F;
$navy: #002e4e;
$orange: #b36106;
$black: #000000;

$pni-colors: (
	white: $white,
	burgundy: $burgundy,
	cyan: $cyan,
	dark-blue: $dark-blue,
	dark-grey: $dark-grey,
	gold: $gold,
	green: $green,
	light-blue: $light-blue,
	light-grey: $light-grey,
	middle-blue: $middle-blue,
	middle-grey: $middle-grey,
	navy: $navy,
	orange: $orange,
	black: $black
);

$border-color: #89837b;

// Spacing ============================================================
$padding: 1.25rem;

// Base Fonts ============================================================
$font-size-base: 62.5%;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

// Font Type ============================================================
$aktiv-grotesk: "aktiv-grotesk";
$adobe-garamond-pro: "adobe-garamond-pro";
$font-awesome: "Font Awesome 5 Pro";

// Grid breakpoints ============================================================
$grid-breakpoints: ( xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1470px) !default;
$grid-size: 160rem;
$content-grid-size: 160rem;
$block-grid-size: 200rem;
$grid-columns: 12 !default;
$grid-gutter-width: 3rem !default;