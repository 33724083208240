@import '../../Styles/variables';
@import '../../Styles/mixins';

@include breakpoint(laptop) {
    .site-header {
        grid-template-areas: 'nav-wrapper nav-wrapper nav-wrapper nav-wrapper nav-wrapper' 'site-alert site-alert site-alert site-alert site-alert';
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: 18.6rem auto;
        overflow: visible;
        position: static;

        .site-wrap, .nav-wrapper {
            align-content: center;
            height: 100%;
            width: 100%;
        }

        .nav-wrapper {
            display: grid;

            ul {
                display: flex;
                text-align: center;
            }
        }

        .mobile-menu-toggle, .mobile-header-logo, .mobile-tools {
            display: none;
        }
    }

    .navigation-wrapper {
        grid-template-areas: 'secondary-nav' 'primary-nav';
        grid-template-rows: 5.6rem 13rem;
        height: auto;
        min-height: 0.1rem;
        overflow-y: visible;
        position: static;
        transform: none;
    }

    .secondary-nav {
        height: 5.6rem;
        order: 0;
        padding: 0 1.7rem;

        .site-wrap.grid {
            grid-auto-flow: column;

            ul {
                display: flex;
            }

            .nav-left {
                order: 0;

                ul li {
                    margin-bottom: 0;
                    margin-right: 4.85rem;
                }

                ul li a {
                    font-size: 1.4rem;
                }
            }

            .nav-right {
                display: flex;
                justify-content: flex-end;
                order: 1;

                ul {
                    border-right: 1px solid rgba(white, 0.4);
                    margin-right: 3.3rem;
                    padding-right: 3.3rem;
                }

                ul:last-child {
                    border: none;
                    margin-right: 0;
                    padding-right: 0;
                }

                ul li {
                    margin-bottom: 0;
                    margin-right: 4.8rem;

                    &:last-child {
                        margin-right: 0;
                    }

                    &.nav-signin {
                        display: block;
                    }
                }

                ul li span {
                    margin-right: 0.9rem;
                }
            }
        }
    }

    .primary-nav {
        height: 13rem;
        order: 1;

        .site-wrap.grid {
            grid-template-columns: auto 2fr;
            grid-template-rows: 1fr;
            position: relative;
        }

        .header-logo {
            align-self: center;
            display: block;
            max-width: 23.9rem;
            padding: 0 1.7rem;
        }

        .nav-wrapper {
            display: block;
            padding-right: 1.7rem;
        }

        .header-search-wrapper {
            animation-name: SearchClose;
            animation-duration: 0.5s;
            float: right;
            height: 5.6rem;
            max-width: 107.3rem;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 0;
            z-index: 100;

            &.preload {
                animation-duration: 0s !important;
            }

            &.open {
                animation-name: SearchOpen;
                animation-duration: 0.5s;
                opacity: 1;
                width: calc(100% - 23.9rem);
            }
        }

        .header-search-wrapper input {
            border: 1px solid $dark-grey;
            height: 5.6rem;
            padding: 1.6rem 0 1.7rem 2rem;
        }

        .header-search-wrapper .search {
            height: 5.6rem;
        }

        .nav-wrapper > ul {
            align-items: center;
            height: 100%;
            justify-content: flex-end;
            margin: 0;
            padding: 0;
        }

        .nav-wrapper > ul > li {
            align-items: center;
            display: flex;
            height: 100%;
            margin-right: 7.9rem;

            &:last-child {
                margin-right: 0;
            }

            & > a {
                height: auto;

                &.main-nav-link:after {
                    background-color: $burgundy;
                    content: '';
                    display: block;
                    height: 0.2rem;
                    right: auto;
                    width: 0;
                    transition: width 0.2s linear;
                }

                &.main-nav-link.current:after {
                    background-color: $burgundy;
                    content: '';
                    display: block;
                    height: 0.2rem;
                    right: auto;
                    width: 100%;
                }
            }

            &:hover > a.main-nav-link, &:focus > a.main-nav-link {
                &:after {
                    width: 100%;
                }
            }
        }

        .mega-menu {
            background-color: white;
            border-top: 1px solid #d0d0d0;
            display: none;
            left: 50%;
            margin-left: -50vw;
            margin-right: -50vw;
            padding: 0;
            right: 50%;
            top: 100%;
            transform: none;
            width: 100vw;

            &.open-mega-menu {
                display: block;
            }

            .nav-back-link {
                display: none;
            }

            .mega-menu-frame {
                background-color: white;
                box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.08);
                padding: 4.25rem 2.7rem;
                width: 100%;

                .site-wrap {
                    display: grid;
                    grid-auto-columns: auto;
                    grid-auto-flow: column;
                    grid-gap: 3.2rem;
                }

                .subnav {
                    border-right: 1px solid #d0d0d0;
                    padding-right: 2.2rem;

                    &:last-child {
                        border-right: none;
                    }
                }

                .subnav > a,
                .subnav > ul > .subnav-header-wrapper:not(:only-child) {
                    height: auto;
                    padding: 0 0 2rem;

                    &:not(:first-child) {
                        padding-top: 2rem;
                    }
                }

                .subnav > ul {
                    display: block;
                    padding: 0;

                    &:not(:last-child) {
                        margin-bottom: 3rem;
                    }
                }

                .subnav > ul.subnav-columns {
                    column-count: 2;
                    max-height: 24.5rem;
                }

                .subnav > ul li {
                    text-align: left;

                    &:not(:last-child):not(.subnav-header-wrapper) {
                        margin-bottom: 1rem;
                    }
                }

                .subnav > ul li a {
                    padding: 0;

                    &:hover:not(.subnav-header-stack) {
                        color: $dark-blue;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .theme-inservco {
        .secondary-nav {
            .nav-right ul li a {
                font-weight: 500;
            }

            .desktop-tools {
                padding-right: 1.7rem;
                display: block;

                .desktop-user {
                    color: white;
                    font-weight: 600;

                    .fa-lock-alt {
                        margin-right: 7px;
                    }
                }
            }
        }
    }
}

@keyframes SearchOpen {
	from {
		opacity: 0;
		width: 0;
	}
	to {
		opacity: 1;
		width: calc(100% - 23.9rem);
	}
}

@keyframes SearchClose {
	from {
		opacity: 1;
		width: calc(100% - 23.9rem);
	}
	to {
		opacity: 0;
		width: 0;
	}
}