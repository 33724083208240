@include breakpoint(tablet) {
	.st-block {
		.container {
			padding: 7.7rem 4.8rem 12.1rem;
		}

		.quotation-mark {
			margin: 0 auto 4.21rem;
			width: 44px;
			height: 34px;
		}

		&-quote {
			font-size: 3.2rem;
			letter-spacing: 0.64px;
			line-height: 1.31;
		}
	}
}