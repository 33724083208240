@import "variables";
@import "mixins";

@include breakpoint(tablet) {
    // HTML Tags ============================================================
    blockquote {
        padding: 7.5rem 8rem;
    }

    // Layout ============================================================
    main > section {
        padding: 0;
    }

    .block > section {
        margin-left: auto;
        margin-right: auto;
    }

    .grid {
		grid-auto-columns: 1fr;

        &.g-2 {
            grid-template-columns: repeat(2, 1fr);
        }

        &.g-3 {
            grid-template-columns: repeat(3, 1fr);
        }

        &.g-4 {
            grid-template-columns: repeat(4, 1fr);
        }

        &.g-5 {
            grid-template-columns: repeat(5, 1fr);
        }

        &.g-1-2 {
            grid-template-columns: 1fr 2fr;
        }

        &.g-1-3 {
            grid-template-columns: 1fr 3fr;
        }

        &.g-1-1-2 {
            grid-template-columns: 1fr 1fr 2fr;
        }

        &.g-1-2-1 {
            grid-template-columns: 1fr 2fr 1fr;
        }

        &.g-1-2-2 {
            grid-template-columns: 1fr 2fr 2fr;
        }

        &.g-2-1 {
            grid-template-columns: 2fr 1fr;
        }

        &.g-2-2-1 {
            grid-template-columns: 2fr 2fr 1fr;
        }

        &.g-2-1-1 {
            grid-template-columns: 2fr 1fr 1fr;
        }

        &.g-3-1 {
            grid-template-columns: 3fr 1fr;
        }
        &.g-3-2 {
            grid-template-columns: 3fr 2fr;
        }
        &.g-5-7 {
            grid-template-columns: 5fr 7fr;
        }
        
        &-center {
            align-self: center;
        }
    }

    // Helpers ====================================================
    .desktop {
        display: block;
    }

    .mobile {
        display: none;
    }

    .m-resp {
        margin-top: 4.8rem;
        margin-bottom: 4.8rem;
    }
}

@include breakpoint(laptop) {
    .m-resp {
        margin-top: 8rem;
        margin-bottom: 8rem;
    }
}