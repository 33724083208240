@include breakpoint(tablet) {
	.adj-block {
		.grid {
			grid-auto-columns: unset;
			grid-template-columns: unset;
		}
	}
}

@include breakpoint(laptop) {
	.adj-block {
		&:not(.full-width) {
			max-width: $content-grid-size;
		}

		&:not(.split-screen) .adj-block-img {
			height: 80rem;
		}

		&-img {
			max-height: 80rem;
		}

		.content-wrap-half {
			width: 100%;
			max-width: 80rem;
			margin: auto;
		}
		
		&-txt {
			align-self: center;
			margin: 0 auto;
			max-width: 75rem;
			text-align: inherit;

			img {
				margin-left: 0;
			}
		}

		&-title {
			margin-bottom: 4.2rem;
		}

		&-desc {
			margin-bottom: 5.1rem;

			&:last-child {
				margin-bottom: 0;
			}
		}

		&-left {
			.content-wrap-half {
				margin-left: 0;
			}

			.adj-block-txt {
				margin-right: 0;

				img {
					margin-left: 0;
				}
			}
		}
		
		&-right {
			.adj-block-img {
				order: 1;
			}

			.content-wrap-half {
				margin-right: 0;
				order: 0;
			}
	
			.adj-block-txt {
				margin-left: 0;

				img {
					margin-right: 0;
				}
			}
		}

		.btn-block-wrapper {
			justify-content: left;
		}

		.grid {
			grid-auto-columns: 1fr;

			&.g-2 {
				grid-template-columns: repeat(2, 1fr);
			}
		}
	}
}