@include breakpoint(tablet) {
    .link-block .content-wrap {
        grid-gap: 3.2rem;

        &.grid.g-2 {
            grid-template-columns: repeat(6, 1fr);

            & .link-block-list {
                grid-column-end: span 2;

                &:first-child {
                    grid-column-start: 2;
                }
            }
        }
    }
}
