@import '../../Styles/variables';
@import '../../Styles/mixins';

@include breakpoint(tablet) {
    .footer-alt {
        display: block;

        .footer-nav {
            padding: 6.3rem 1.7rem 9.6rem;
        }
    }
}