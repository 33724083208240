@include breakpoint(tablet) {
	.fw-cta-block {
		min-height: 62rem;

		&:not(.full-width) {
			max-width: $content-grid-size;
		}

		&-img {
			min-height: 62rem;
		}
	
		&-content {
			max-width: 57rem;
			padding: 6rem 6.4rem;
			
			&.position-left {
				margin-left: 0;
			}
	
			&.position-right {
				margin-right: 0;
			}
		}
	}
}

@media (min-width: 691px) {
	.fw-cta-block {
		&-img-mobile {
			display: none;
		}
	}
}
