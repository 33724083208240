@include breakpoint(tablet) {
  .find-shop-form {
    &.pni-form-container {
      padding: 8.6rem 1.7rem 19.1rem;
    }

    .std-copy-block {
      .site-wrap {
        max-width: unset;
      }
    }

    .grid {
      &.g-2.gg-3 {
        gap: 32px;
      }
    }
  }
}
