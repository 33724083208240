@include breakpoint(tablet) {
    .pni-form {
        &-container {
            padding: 8.6rem 0 19.1rem;
            width: 95%;
        }

        form .grid {
            &.g-2,
            &.g-3 {
                column-gap: 6.4rem;
            }

            &-center label {
                align-self: center;
                margin-bottom: 0;
            }
            
            &.has-separator {
                grid-gap: 0;
                grid-template-columns: 1fr 6.4rem 1fr;
    
                .form-separator {
                    align-self: center;
                    display: block;
                    justify-self: center;
                    margin-top: 50%;
                }
            }
        }

        .step-nav {
            .step:not(.current) {
                display: block;
            }
        }

        .signup-oic-form {
            &__result {
                display: inline-block;
                padding: 2.4rem;
            }
        }
    }
}

.pni-form {
    h1 {
        font-size: 48px;
    }

    h4 {
        font-size: 22px;
        padding-bottom: 2.8rem;
        padding-top: 2.8rem;
    }

    .recurring-payment-plan {
        border-collapse: collapse;

        th, td {
            border: solid 1px $middle-grey;
            padding: 0.5em 1em;
        }
    }
}