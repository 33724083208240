@include breakpoint(tablet) {
	.img-hover-block {
		.img-card {
			&:hover .img-card-content-wrapper {
				transform: translateY(0);
			}
		}

		.img-hover-img-wrapper {
			.img-card-img {
				height: 570px;
			}
		}

		.img-hover-content {
			padding: 0 3.2rem;
			position: relative;
			top: 14.8rem;
		}

		.img-card-content-wrapper {
			background-color: rgba($navy, 0.9);
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			transform: translateY(100%);
			transition: transform 0.25s ease-in-out;
			width: 100%;
		}
	}
}