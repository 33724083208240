@function to-rem($size) {
    @return $size / 16px * 1rem;
}

@mixin transition($type: all, $transition-time: 0.25s, $ease: ease) {
    transition: $type $transition-time $ease;
}

@mixin img-responsive {
    display: block;
    height: auto;
    max-width: 100%;
}

@mixin clearfix {
    &::after {
        clear: both;
        content: '';
        display: block;
    }
}

@mixin breakpoint($point) {
    /* 1120px */
    @if $point == desktop {
        @media (min-width: 70em) {
            @content;
        }
    }
    /* 1024px */
    @else if $point == laptop {
        @media (min-width: 64em) {
            @content;
        }
    }
    /* 800px */
    @else if $point == tablet {
        @media (min-width: 50em) {
            @content;
        }
    }
    /* 640px */
    @else if $point == tabletMini{
        @media (min-width: 40em) {
            @content;
        }
    }
    @else if $point == mobileonly {
        @media (max-width: 40em) {
            @content;
        }
    }
    @else if $point == tabletMini-d {
        @media (max-width: 50em) {
            @content;
        }
    }
}