@include breakpoint(tablet) {
	.testimonial-block {
		.st-block {
			.quotation-mark {
				width: 44px;
				height: 34px;
			}

			&-quote {
				font-size: 2.8rem;
				font-weight: 600;
				line-height: 1.29;
			}
		}

		.testimonial-carousel {
			padding: 3.33rem 0 4.58rem 9.8rem;

			.slick-slide {
				max-width: 47rem;
			}
		}
	
		.carousel-slide {
			&-content {
				max-width: 47rem;
			}
		}
	}
}