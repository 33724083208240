@import '../../Styles/variables';
@import '../../Styles/mixins';

@include breakpoint(tablet) {
    .footer-wrapper {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 9.6rem 49.15rem 10.85rem auto;

        .site-wrap.grid {
            align-content: center;
            height: 100%;
        }
    }

    .footer-login-wrapper {
        .site-wrap.grid {
            grid-gap: 9.65rem;
        }

        .footer-login:first-child {
            border-bottom: none;
            border-right: 1px solid rgba(white, 0.4);
            padding-bottom: 0;
        }
    }

    .footer-nav {
        border-bottom: 1px solid #d0d0d0;
        display: block;
        padding: 0 1.7rem;

        .site-wrap {
            grid-auto-columns: minmax(auto, 32rem);
            grid-auto-flow: column;
        }

        ul {
            margin-top: 3rem;

            li {
                font-size: 1.6rem;
                margin-bottom: 1.5rem;

                a {
                    font-size: 1.6rem;
                }
            }

            li:first-child {
                margin-bottom: 3rem;
            }
        }
    }

    .footer-social-wrapper {
        .site-wrap.grid {
            display: grid;
            grid-gap: 2.5rem;
            grid-template-columns: 3fr 1fr 1fr;
        }

        .footer-social-wrapper .footer-social.grid-flex,
        .footer-application.grid-flex {
            align-items: center;
            display: flex;
        }

        .footer-application {
            justify-self: start;
            margin-bottom: 0;
            order: 0;

            li:last-child {
                padding-right: 2.5rem;
            }
        }

        .call {
            align-self: center;
            justify-self: end;
            margin: 0;
            order: 2;
        }

        .footer-social.grid-flex {
            align-self: center;
            justify-self: end;
            margin-bottom: 0;
            order: 3;
        }
    }

    .footer-otherLinks {
        display: block;

        .site-wrap.grid {
            height: auto;
        }

        ul {
            display: flex;

            &:first-child {
                margin-bottom: 6.3rem;
            }

            &:last-child {
                justify-content: flex-end;
            }

            li {
                margin-right: 6.7rem;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .copyright {
            text-align: right;
        }
    }

    .theme-inservco {
        .footer-wrapper {
            grid-template-rows: 9.6rem 13.15rem 10.85rem auto;
        }

        .footer-nav {
            .site-wrap {
                grid-auto-columns: minmax(auto, 24rem);
            }

            ul {
                li {
                    a {
                        color: #3f3f3f;
                    }
                }
            }
        }

        .footer-otherLinks {
            ul {
                &:first-child {
                    margin-bottom: 3.3rem;
                }
            }
        }

        .footer-social-wrapper {
            .site-wrap.grid {
                grid-template-columns: 7fr 2fr 1fr;
            }

            .call {
                color: #3f3f3f;
                text-transform: uppercase;

                a {
                    color: #3f3f3f;
                }
            }
        }
    }
}