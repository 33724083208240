@include breakpoint(laptop) {
    .interior-page {
        &-body {
            grid-gap: 9.5rem;
            grid-template-columns: 31.2rem auto;
            margin-top: 6rem;
        }

        &-sidebar {
            display: block;

            .accordion-block {
                .accordion-button {
                    font-size: 1.6rem;

                    &::after {
                        font-size: 1.5rem;
                    }

                    &[aria-expanded=true],
                    &:hover {
                        color: $middle-grey;

                        span::after {
                            width: 100%;
                        }
                    }

                    span {
                        &::after {
                            background-color: $burgundy;
                            content: '';
                            display: block;
                            height: 0.2rem;
                            margin-top: 0.5rem;
                            right: auto;
                            width: 0;
                            transition: width 0.2s linear;
                        }
                    }
                }

                .accordion-body {
                    padding: 0;
                }
            }

            .sidebar-navigation {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    a {
                        border-top: 1px solid rgba($border-color, 0.3);
                        display: block;
                        font-size: 1.6rem;
                        padding: 2.1rem 0;
                        width: 100%;
                    }
                }
            }
        }

        &-main {
            .interior-main-top {
                display: block;
                margin-bottom: 3rem;

                &.g-2 {
                    grid-template-columns: 62.3rem auto;
                }

                .interior-page {
                    &-subtitle {
                        margin: 3rem 0 2rem;
                    }
                }
            }

            h3 {
                margin-top: 3rem;
            }
        }
    }

    .theme-inservco {
        .interior-page {
            &-body {
                margin-bottom: 6rem;
            }

            &-top-image img {
                display: block;
            }
        }
    }
}

/* 1430px */
@media (min-width: 90em) {
    .interior-page-main .interior-main-top {
        display: grid;
    }
}
