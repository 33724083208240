
@include breakpoint(tablet) {
    section.carousel-block {
        max-height: 60.4rem;

        .sign-in-block {
            display: grid;
        }

        &:not(.full-width) {
            max-width: $content-grid-size;

            .hero-carousel .carousel-slide-content {
                & > *:not(.btn-block-wrapper) {
                    margin-left: 0;
                    max-width: 66.4rem;
                }

                &.text-cener > *:not(.btn-block-wrapper) {
                    margin-left: auto;
                }

                &.text-right > *:not(.btn-block-wrapper) {
                    margin-left: auto;
                    margin-right: 0;
                }
            }
        }
    }

    .hero-carousel {
        min-width: 0;

        picture {
            display: block;
            overflow: hidden;
        }

        img {
            display: block;
            height: 60.4rem;
            object-fit: cover;
            object-position: center;
            width: 100%;
        }

        .carousel-slide {
            min-height: 39rem;

            &-img-mobile {
                display: none;
            }
        }

        .carousel-slide-content {
            left: 50%;
            margin: 0 auto;
            max-width: 102.4rem;
            padding: 0 12rem;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100%;

            .carousel-slide-title {
                margin: 0 auto 3rem;
            }

            .carousel-slide-desc {
                margin: 0 auto 3rem;
            }

            &.position-left {
                left: 0;
                transform: translate(0, -50%);
            }

            &.position-right {
                left: auto;
                right: 0;
                transform: translate(0, -50%);
            }
        }

        .slick-arrow {
            display: block;
        }

        .slick-dots {
            display: none !important;
        }
    }

    .theme-inservco {
        .carousel-slide.nobackground-color {
            background: transparent !important;

            &.theme-dark {
                picture {
                    box-shadow: inset 0 0 0 100vmax rgb(0 0 0);

                    img {
                        opacity: 0.46;
                    }
                }
            }

            &.theme-light{ 
                img {
                    opacity: 1;
                }
            }
        }

        .hero-carousel {
            .carousel-slide-content {
                max-width: 110rem;
                padding: 0 12rem;

                &.text-right {
                    left: 55%;
                }

                &.text-left {
                    left: 45%;
                }
            }
        }
    }
}
@include breakpoint(tablet) {
    .theme-inservco {
        .hero-carousel {
            picture {
                box-shadow: none;
            }

            .carousel-slide-content {
                .desktop__only {
                    display: block;
                }

                .mobile__only {
                    display: none;
                }

                &.desktop__only {
                    display: block;
                }

                &.mobile__only {
                    display: none;
                }

                &.text-center {
                    text-align: center;
                }

                &.text-left {
                    text-align: left;

                    .btn-block-wrapper {
                        justify-content: flex-start;
                    }
                }

                &.text-right {
                    text-align: right;

                    .btn-block-wrapper {
                        justify-content: flex-end;
                    }
                }
            }
        }
    }
}

@media (min-width: 90em) {
    .theme-inservco { 
        section .carousel-block {
        }

        .hero-carousel {
            picture {
                box-shadow: none;
            }

            img {
                opacity: 1;
            }

            .carousel-slide-content {
                max-width: 110rem;

                &.text-right {
                    left: 70%;
                }

                &.text-left {
                    left: 30%;
                }

            }
        }
    }
}