@import '../../Styles/mixins';

@include breakpoint(tablet) {
    .std-copy-block {
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        width: 100%;

        .site-wrap {
            width: 100%;
            max-width: 57.7rem;
        }

        p {
            margin-top: 2.5rem;
        }

        .btn-block-wrapper {
            margin-top: 5rem;
        }
    }

    .theme-inservco {
        .std-copy-block {
            .site-wrap {
                width: 100%;
                max-width: 90rem;
                text-align: center;

                .btn-block-wrapper {
                    justify-content: center;
                }
            }

            &.std-copy-block-margins {
                max-width: 150rem;
                margin: 7rem auto; 
            }
        }
    }
}