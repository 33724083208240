@include breakpoint(tablet) {
    .find-agent-form {
        &.pni-form-container {
            padding: 8.6rem 1.7rem 19.1rem;
        }

        .std-copy-block {
            .site-wrap {
                max-width: unset;
            }
        }

        .grid {
            &.g-2.gg-3 {
                gap: 32px;
            }

            h5 {
                margin-bottom: 0;
            }

            .custom-checkbox {
                margin-bottom: 0;
            }
        }
    }

    .theme-inservco {
        .pni-form {
            background-color: white;

            .site-wrap {
                max-width: 100%;
            }
        }

        .find-agent-form {
            max-width: 160rem;
            margin: 0 auto; 

            .map-wrapper .agents {
                /*div:last-of-type {
                    border-bottom: 1px solid rgba(137,131,123,.3);
                }*/
            }
        }
    }
}
